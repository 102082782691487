import { Fragment, lazy, Suspense, useEffect } from "react";
import { ToastContainer, Zoom } from "react-toastify";
import { loginAction } from "core/redux/account/account.action";
import { connect, useSelector } from "react-redux";
import { localStorage } from "core/helper";
import { Spinner } from "components";
// import { setCommonDataAction } from "core/redux/account/common";
// import { commonService } from "core/service";
// import { COMMON_DATA } from "core/service/api.url.service";

const PreLogin = lazy(() => import("../prelogin"));
const PostLogin = lazy(() => import("../postlogin"));

const Container = (props) => {
  // const token = useSelector((state) => state.account?.token);
  // const authUser = useSelector((state) => state.account?.authUser);
  const { loginAction, setCommonDataAction } = props;
  const isLoggedIn = useSelector((state) => state.account?.isLoggedIn);

  const checkSessionlogin = () => {
    const token = localStorage.getAuthToken();
    if (token === "") {
      return false;
    }
    const authUser = localStorage.getAuthUser();
    loginAction(authUser);
  };

  useEffect(() => {
    checkSessionlogin();
  }, []);

  return (
    <div
      data-typography="poppins"
      data-theme-version="light"
      data-layout="vertical"
      data-nav-headerbg="color_1"
      data-headerbg="color_1"
      data-sidebar-style="full"
      data-sibebarbg="color_1"
      data-sidebar-position="fixed"
      data-header-position="fixed"
      data-container="wide"
      direction="ltr"
      data-primary="color_1"
      data-sibebartext="color_1"
    >
        <Suspense fallback={<Spinner />}>
          {isLoggedIn && <PostLogin />}
          {!isLoggedIn && <PreLogin />}
          {/* <PostLogin /> */}
        </Suspense>
        <ToastContainer draggable={false} transition={Zoom} theme="dark" />
    </div>
  );
};

const mapDispatchToProps = {
  loginAction,
};

export default connect(null, mapDispatchToProps)(Container);
