// import constant
import { SET_LOGGED_IN_USER, REMOVE_LOGGED_IN_USER, SET_LOGGED_USER_DATA } from '../constant';
import { setAuthUser, removeAuthUser, setLoggedUser } from '../../../core/helper/localstorage';

const initialState = {
    isLoggedIn: false,
    token: '',
    authUser: '',
    profile: null
};

const account = (state = initialState, action) => {
    const { type, payload } = action
    switch (type) {
        case SET_LOGGED_IN_USER:
            setAuthUser(payload);
            return {
                ...state,
                ...payload
            }
        case REMOVE_LOGGED_IN_USER:
            removeAuthUser();
            return {
                ...state,
                ...payload
            }
        case SET_LOGGED_USER_DATA:
            setLoggedUser(payload);
            return {
                ...state,
                profile: payload
            }
        default:
            return state;
    }
};

export default account;