import axios from "../config/axios";
import { utils } from "../helper";
import { COMMON_DATA, UPLOAD_PHOTOS } from "./api.url.service";

const Common = {
    getAllCommonData: function () {
        return axios.get(COMMON_DATA).then((resp) => {
            return resp.data
        }).catch(error => {
            let msg = utils.handleErr(error);
            utils.showErrMsg(msg);
        })
    },
    imageUpload: function (formData) {
        const headers = { "Content-Type": "multipart/form-data", "accept": "*/*" };
        return axios.post(UPLOAD_PHOTOS, formData, headers)
            .then((resp) => resp.data)
            .catch(err => utils.showErrMsg(utils.handleErr(err)));
    }
}

export default Common;