export const MSG = {
  SUCCESS_CODE: 200,
  DOCUMENT_CHECK_CODE: 1014,
  DOCUMENT_REJECT_CODE: 1015,
  DOCUMENT_VERIFY_CODE: 1011,
  WIZARD_NO_RECORS_MESSAGE: 1013,
  FILTER_NO_RECORDS_FOUND: 1013,
  ORDER_STATUS_STATUS_CODE: 1002,
  EXIST_INVENTORY_CODE: 1009,
  LOGIN_MSG: "login successfully",
  PASSWORD_REGEX_MSG:
    "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character",
  PASSWORD_REGEX_EXP:
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
  REQ_PWD: "Password",
  REQ_CONFIRM_PWD: "Confirm password",
  PASSWORD_NOT_MATCH: "Passwords does not match",
  REQ_PHONE_CODE: "Phone code",
  PHONE_REGEX_MSG: "Phone number is not valid",
  PHONE_INVALID: "Invalid Phone number",
  REQ_PHONE_NUM: "Phone number",
  REQ_MIN_NUM: "Minimum 10 numbers are expected",
  REQ_MAX_NUM: "Maximum 10 numbers are allowed",
  PASSWORD_REQ: "Password is required field",
  PASSWORD_MIN: "Minimum Characters is required",
  PASSWORD_MAX: "Maximun 20 Characters are allowed",
  MAX_NAME: "Maximum 15 Characters are allowed",
  PASSWORD_REGEX_MSG:
    "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character",
  TERMS_AND_CONDITON: "The terms and conditions must be accepted",
  REQ_GST_CERTIFICATE: "Please upload the certificate",
  REQ_REGISTERD_CERTIFICATE: "Please upload the certificate",
  REQ_COMPANY_LOGO: "Please upload the logo",
  REQ_OWNER_ID: "Please upload the identity",
  REQ_GST_IN: "Gst number",
  REQ_DOOR_NO: "Door no",
  REQ_STREET: "Street",
  REQ_CITY: "City",
  REQ_STATE: "State",
  REQ_COUNTRY: "Country",
  REQ_ZIPCODE: "Zipcode",
  REQ_ACCOUNT_TYPE: "Account type",
  REQ_COMPANY_TYPE: "Company type",
  REQ_COMPANY_NAME: "Company name",
  REQ_DISPLAY_NAME: "Display name",
  REQ_ROLE: "Role",
  REQ_OWNER_IDEDNITY: "Owner Identity",
  REQ_BANK_NAME: "Bank name",
  REQ_IFSC_CODE: "Ifsc Code",
  REQ_BRANCH: "Branch",
  REQ_ACCOUNT_NO: "Account no",
  REQ_BANK_PROOF: "Please upload the proof",

  REQ_NAME: "Name",
  REQ_FNAME: "First name",
  REQ_LNAME: "Last name",
  REQ_EMAIL: "Email",
  REQ_ALT_EMAIL: "Email is already registered",
  INVALID_EMAIL: "Invalid email",
  PHONE_EXCEED: "Exceed numbers",

  REQ_MAKER_NAME: "Maker name",
  REQ_SEGMENT_NAME: "Segment name",
  REQ_MODEL_NAME: "Model name",
  REQ_GENERATION_NAME: "Generation name",
  REQ_VARIANT_NAME: "Variant name",
  REQ_SEGMENT: "Segment",
  REQ_BRAND_NAME: "Brand name",
  REQ_ORIGIN: "Origin",
  REQ_IMAGE: "Please upload a image",
  REQ_DESCRIPTION: "Description",
  REQ_ISTRUSTED: "Is trusted",
  REQ_ISPOPULAR: "Is popular",
  REQ_MESSAGE: "Message",
  REQ_BODYSTYLE: "Body style",
  REQ_GENIUNE: "Genuine",
  REQ_IS_CAR_MAKER: "Is car maker",
  REQ_LOCATION: "Location",
  REQ_FROM_DATE: "From date",
  REQ_TO_DATE: "To date",
  REQ_ENGINE_CODE: "Engine code",
  REQ_ENGINE_TYPE: "Engine type",
  REQ_FUEL_TYPE: "Fuel type",
  REQ_CHASEIS_TYPE: "Chasis type",
  REQ_YEAR_OF_RELEASE: "Year of release",
  REQ_COLOR: "Color",
  REQ_ENGINE_POWER: "Engine power",
  REQ_ENGINE_LITERS: "Engine liters",
  REQ_MOTOR_POWER: "Motor power",
  REQ_MILEAGE: "Mileage",
  REQ_COMPONANT: "Componant name",
  // REQ_SUB_COMPONANT: "Sub Componant name",
  REQ_PART_NAME: "Part name",
  REQ_PART_NO: "Part no",
  REQ_PRICE: "Price",
  REQ_MRP: "MRP",
  REQ_VARIANTS: "Variants",
  REQ_HSN_CODE: "HSN Code",
  REQ_CATEGORY: "Category",
  REQ_PARENT_CATEGORY: "Parent category",
  REQ_PARENT_COMPONANT: "Parent componant",
  REQ_CATEGORY_NAME: "Category name",
  REQ_MAP_COMPONENT: "Map component name",
  REQ_COMPONANT_LEVEL: "Componant level",
  REQ_CATEGORY_LEVEL: "Category level",
  REQ_CATEGORY_LEVEL: "Category level",
  REQ_ATTRIBUTE_NAME: "Attribute name",
  REQ_ATTRIBUTE_VALUE: "Attribute value",
  REQ_PART_IMAGE: "Please upload part image",
  REQ_PART_IMAGES: "Please upload part images",
  REQ_REMARKS: "Remarks",
  REQ_CHECK_TYPE: "Check type",
  REQ_SITE_TIME: "Site name",
  REQ_META_TITLE: "Meta title",
  REQ_META_DESCRIPTION: "Meta Description",
  REQ_SUPPORT_EMAIL: "Support Email",
  REQ_PLAY_STORE_URL: "Play store url",
  REQ_CONTACT_NO: "Contact number",
  REQ_APP_STORE_LINK: "App store link",
  REQ_NOTES: "Notes",
  REQ_FACEBOOK_LINK: "Facebook link",
  REQ_YOUTUBE_LINK: "Youtube link",
  REQ_TWITTER_LINK: "Twitter link",
  REQ_INSTA_LINK: "Instagram link",
  REQ_SITE_LOGO: "Please upload a site logo",
  REQ_TITLE: "Title",
  REQ_BANNER_IMAGE: "Please upload a banner image",
  REQ_URL: "URL",
  REQ_SMS_CODE: "SMS code",
  REQ_QUESTION: "Question is required",
  REQ_ANSWER: "Answer is required",

  REQ_DISCOUNT: "Discount",
  REQ_STOCK: "Stock",

  REQ_DISCOUNT_NAME: "Discount name",
  REQ_DISCOUNT_TYPE: "Discount Type",
  REQ_DISCOUNT_AMOUNT: "Discount Amount",

  REQ_ITEM_STATUS_CHAGE_TYPE: "Status",

  REQ_ALT_NAME: "Alternate name",
  REQ_PAN_CARD: "Please upload the pancard",
  EMAIL_EXISTS: "Email exist",
  PHONE_EXISTS: "Phone exist",
  PART_NAME_EXISTS: "Part name exists",
  PART_NO_EXISTS: "Part number exists",
  REQ_EMAIL: "Email",

  REQ_VENDOR: "Vendor",
  REQ_REFERENCE_NO: "Reference No",
  REQ_PURCHASE_DATE: "Date",
  REQ_TERMS_AND_CONDITION: "Terms and condition",
  REQ_NOTES: "Notes",
  REQ: "Required",
  REQ_INVOICE: "Please upload the invoice",
  REQ_ITEM_NAME: "Item name",
  REQ_QUANTITY: "Quantity",

  REQ_BENIFICIARY_NAME: "Beneficiary name",
  NOT_ALLOWED_SPECIAL_CHAR: "Special characters not allowed",

  PHONE_REGEX:
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
  PASSWORD_REGEX:
    "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character",

  GST_REGEX: /^([0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1})$/,
  GST_INVALID_MSG: "GST number should be capital",

  IFSC_REGEX: /^[A-Z]{4}0[A-Z0-9]{6}$/,
  IFSC_REGEX_MSG: "Invalid IFSC code",

  REQ_PASSWORD: "Password",
  REQ_CONFIRM_PASSWORD: "Confirm password is a required",

  REQ_OLD_PASSWORD: "Old Password",
  REQ_NEW_PASSWORD_MATCH: "Old password and new password is equal",
  REQ_PASSWORD_MIN: "Minimum 8 Characters is required",
  REQ_PASSWORD_NOT_MATCH: "Passwords does not match",

  REQ_TYPE: "Type",
  REQ_ACTION: "Action",
  REQ_REASON_ADJUSTOMENT: "Reason adjustment",
  REQ_ALT_PHONE_NUM: "Phone number is already registered",
  REQ_ALT_COMPANY_NAME: "Company name is already registered",
  REQ_COMPANY_NAME: "Company name",
};

export const DEFAULT_SORT_BY = "_id";
export const DEFAULT_SORT = -1;
export const DEFAULT_SORT_CREATBY = "createdAt";
export const DEFAULT_PER_PAGE = 10;
export const DEFAULT_SKIP = 0;
export const INPUT_SEARCH_FILTER = {
  skip: 0,
  limit: DEFAULT_PER_PAGE,
  sortBy: DEFAULT_SORT_BY,
  sort: DEFAULT_SORT,
  search: "",
};
export const FILTER = {
  skip: 0,
  limit: DEFAULT_PER_PAGE,
  sortBy: DEFAULT_SORT_BY,
  sort: DEFAULT_SORT,
  search: "",
  filter: {},
};
export const DEFAULT_FILTER = {
  skip: 0,
  limit: DEFAULT_PER_PAGE,
  sortBy: DEFAULT_SORT_BY,
  sort: DEFAULT_SORT,
  // search: ""
};

export const FILTER_OUT_SEARCH = {
  skip: 0,
  limit: DEFAULT_PER_PAGE,
  sortBy: DEFAULT_SORT_BY,
  sort: DEFAULT_SORT,
  filter: {},
};

export const DEFAULT_ADV_FILTER = {
  skip: 0,
  limit: DEFAULT_PER_PAGE,
  sortBy: DEFAULT_SORT_BY,
  sort: DEFAULT_SORT,
  filter: {},
  search: "",
};
export const USERS_FILTER = {
  skip: 0,
  limit: DEFAULT_PER_PAGE,
  sortBy: DEFAULT_SORT_BY,
  sort: DEFAULT_SORT,
  search: "",
  filter: {},
};
export const ROLE = {
  ADMIN: 10,
  DISTRIBUTER: 20,
  RETAILER: 30,
  CUSTOMER: 40,
  MECHANIC: 50,
  FINANCE: 60,
  STAFF_ROLE: 70,
};

export const DOCUMENT_CHECK_TYPE = {
  DOCUMENT_CHECK: 10,
  DOCUMENT_VERIFY: 20,
};

export const DOCUMENT_CHECK_STATUS = {
  IS_RAISED: 20,
  IS_CHECKED: 30,
  IS_REJECTED: 60,
};

export const STATUS = {
  ACTIVE: 10,
  IN_ACTIVE: 20,
};

export const CATEGORY = {
  MAIN: 10,
  SUB: 20,
  END: 30,
};

export const COMPONANT = {
  MAIN: 10,
  SUB: 20,
  END: 30,
};
