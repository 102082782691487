import logo from "pages/assets/images/logo.png";
import logo_text from "pages/assets/images/logo-text.png";
import avatar from "pages/assets/images/admin-image.jpg";
// import profile from "pages/assets/images/profile/17.jpg";
import { Dropdown, Nav, Form, Row } from "react-bootstrap";

import React, { Fragment, useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import {
  chatToggleAction,
  sidebarToggleAction,
} from "../../core/redux/account/common";
import { logoutAction } from "../../core/redux/account/account.action";
import { Link, useNavigate } from "react-router-dom";
import { LOGIN_PATH } from "../../pages/routes/routes";
import { CONST, utils } from "../../core/helper";
// import { setAuthToken, setAuthUser } from "../../core/helper/localstorage";
import { useTranslation } from "react-i18next";
import { GET_LOGGED_USER, authService } from "core/service";

const Header = (props) => {
  const { sidebarToggleAction, chatToggleAction, logoutAction } = props;
  const sidebarToggle = useSelector((state) => state.common?.sidebarToggle);
  const authProfile = useSelector((state) => state.account?.profile);
  const reloadProfile = useSelector((state) => state.common?.reloadProfile);
  const commonData = useSelector((state) => state.common?.commonData);
  const [notifyCount] = useState(0);
  const [user, setUser] = useState(null);
  console.log("user", user);
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  const getValuesCommon = (key, value) => {
    const data = commonData[key]?.find((ele) => ele.code === value);
    return data ? data.label : "";
  };

  const loadLoggedUser = async () => {
    const resp = await authService.loggedUser(GET_LOGGED_USER);
    if (resp && resp.meta.code === CONST.MSG.SUCCESS_CODE) {
      const { data } = resp;
      setUser(data);
    }
  };

  const profileDropdown = React.forwardRef(({ children, onClick }, ref) => (
    <div className="header-info" ref={ref} onClick={onClick}>
      <a className="nav-link d-flex" href="#">
        <div className="header-info">
          {user?.firstName && user?.lastName && (
            <span className="text-black">
              <strong>{user?.firstName + " " + user?.lastName}</strong>
            </span>
          )}
          <p className="fs-12 mb-0">{getValuesCommon("roles", user?.role)}</p>
        </div>
        <img src={avatar} width="20" alt="" />
      </a>
    </div>
  ));

  useEffect(() => {
    loadLoggedUser();
  }, [reloadProfile]);

  const handleLogout = () => {
    logoutAction();
    navigate(LOGIN_PATH);
    utils.showSuccessMsg("logout successfully");
  };

  //Creating a method to change the language onChnage from select box
  const changeLanguageHandler = (e) => {
    const languageValue = e.target.value;
    i18n.changeLanguage(languageValue);
  };

  return (
    <Fragment>
      <div className="nav-header">
        <a href="#" className="brand-logo">
          <img className="logo-abbr" src={logo} alt="logo" />
          <img className="logo-compact" src={logo_text} alt="logo_text" />
          <img className="brand-title" src={logo_text} alt="logo_text" />
        </a>
        <div className="nav-control">
          <div
            className={sidebarToggle ? "hamburger is-active" : "hamburger"}
            onClick={sidebarToggleAction}
          >
            <span className="line"></span>
            <span className="line"></span>
            <span className="line"></span>
          </div>
        </div>
      </div>
      <div className="header">
        <div className="header-content">
          <nav className="navbar navbar-expand">
            <div className="collapse navbar-collapse justify-content-between">
              <div className="header-left">
                <div className="dashboard_bar">
                  <div className="input-group search-area d-lg-inline-flex d-none">
                    <div className="input-group-append">
                      <button className="input-group-text search_icon search_icon">
                        <i className="flaticon-381-search-2"></i>
                      </button>
                    </div>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Search here..."
                    />
                  </div>
                </div>
              </div>

              <Row>
                <Form.Select size="sm" onChange={changeLanguageHandler}>
                  <option value="en">English</option>
                  <option value="hn">Hindi</option>
                </Form.Select>
              </Row>

              <Nav as={"ul"} className="navbar-nav header-right">
                <Nav.Item as={"li"} className="dropdown notification_dropdown">
                  <Nav.Link href="#">
                    <Dropdown align={"end"}>
                      <Dropdown.Toggle as={"a"} className="nav-link">
                        {utils.headerNotificationIcon(15, 15)}
                        {notifyCount > 0 && (
                          <span className="badge light text-white bg-primary rounded-circle">
                            {Number(notifyCount)}
                          </span>
                        )}
                      </Dropdown.Toggle>
                      <Dropdown.Menu className="dropdown-menu-end">
                        <li className="nav-item dropdown notification_dropdown d-block">
                          <div className="widget-media dz-scroll p-3 height380 ">
                            <ul className="timeline">
                              <li>
                                <div className="timeline-panel">
                                  <div className="media me-2">
                                    {/* <img alt="image" width="50" src={avatar} /> */}
                                  </div>
                                  <div className="media-body">
                                    <h6 className="mb-1">
                                      Dr sultads Send you Photo
                                    </h6>
                                    <small className="d-block">
                                      29 July 2020 - 02:26 PM
                                    </small>
                                  </div>
                                </div>
                              </li>
                            </ul>
                          </div>
                          <a className="all-notification" href="#">
                            See all notifications{" "}
                            <i className="ti-arrow-right"></i>
                          </a>
                        </li>
                      </Dropdown.Menu>
                    </Dropdown>
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item as={"li"} className="dropdown notification_dropdown">
                  <Nav.Link
                    href="#"
                    className="nav-link bell bell-link"
                    onClick={chatToggleAction}
                  >
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 28 28"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M25.6666 8.16666C25.6666 5.5895 23.5771 3.5 21 3.5C17.1161 3.5 10.8838 3.5 6.99998 3.5C4.42281 3.5 2.33331 5.5895 2.33331 8.16666V23.3333C2.33331 23.8058 2.61798 24.2305 3.05315 24.4113C3.48948 24.5922 3.99115 24.4918 4.32481 24.1582C4.32481 24.1582 6.59281 21.8902 7.96714 20.517C8.40464 20.0795 8.99733 19.8333 9.61683 19.8333H21C23.5771 19.8333 25.6666 17.7438 25.6666 15.1667V8.16666ZM23.3333 8.16666C23.3333 6.87866 22.2891 5.83333 21 5.83333C17.1161 5.83333 10.8838 5.83333 6.99998 5.83333C5.71198 5.83333 4.66665 6.87866 4.66665 8.16666V20.517L6.31631 18.8673C7.19132 17.9923 8.37899 17.5 9.61683 17.5H21C22.2891 17.5 23.3333 16.4558 23.3333 15.1667V8.16666ZM8.16665 15.1667H17.5C18.144 15.1667 18.6666 14.644 18.6666 14C18.6666 13.356 18.144 12.8333 17.5 12.8333H8.16665C7.52265 12.8333 6.99998 13.356 6.99998 14C6.99998 14.644 7.52265 15.1667 8.16665 15.1667ZM8.16665 10.5H19.8333C20.4773 10.5 21 9.97733 21 9.33333C21 8.68933 20.4773 8.16666 19.8333 8.16666H8.16665C7.52265 8.16666 6.99998 8.68933 6.99998 9.33333C6.99998 9.97733 7.52265 10.5 8.16665 10.5Z"
                        fill="#3E4954"
                      />
                    </svg>
                    {notifyCount > 0 && (
                      <span className="badge light text-white bg-primary rounded-circle">
                        {Number(notifyCount)}
                      </span>
                    )}
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item as={"li"} className="dropdown header-profile">
                  <Nav.Link href="#">
                    <Dropdown>
                      <Dropdown.Toggle as={profileDropdown}></Dropdown.Toggle>
                      <Dropdown.Menu className="dropdown-menu-end">
                        <Dropdown.Item>
                          <Link
                            to={"/profile"}
                            className="ai-icon w-100 d-block"
                          >
                            {utils.userProfileIcon()}
                            <span className="ms-2">Profile </span>
                          </Link>
                        </Dropdown.Item>
                        {/* <a
                          href="/email-inbox/"
                          className="dropdown-item ai-icon"
                        >
                          <svg
                            id="icon-inbox"
                            xmlns="http://www.w3.org/2000/svg"
                            className="text-success"
                            width="18"
                            height="18"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLineCap="round"
                            stroke-linejoin="round"
                          >
                            <path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"></path>
                            <polyline points="22,6 12,13 2,6"></polyline>
                          </svg>
                          <span className="ms-2">Inbox </span>
                        </a> */}
                        <Dropdown.Item>
                          <Link
                            to={"/change-password"}
                            className="ai-icon w-100 d-block"
                          >
                            {utils.changePassword()}
                            <span className="ms-2">Change Password </span>
                          </Link>
                        </Dropdown.Item>
                        <Dropdown.Item>
                          <Link
                            to={"/settings"}
                            className="ai-icon w-100 d-block"
                          >
                            <i className="fa fa-cog "></i>
                            <span className="ms-2">Settings </span>
                          </Link>
                        </Dropdown.Item>
                        <Dropdown.Item>
                          <a
                            href="#"
                            className="ai-icon w-100 d-block"
                            onClick={() => handleLogout()}
                          >
                            {utils.logOut()}
                            <span className="ms-2">Logout </span>
                          </a>
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </Nav.Link>
                </Nav.Item>
              </Nav>
            </div>
          </nav>
        </div>
      </div>
    </Fragment>
  );
};

const mapDispatchToProps = {
  sidebarToggleAction,
  chatToggleAction,
  logoutAction,
};

export default connect(null, mapDispatchToProps)(Header);
