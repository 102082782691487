export const BASE_PATH = "";

export const PRE_LOGIN_PATH = `${BASE_PATH}/`;
export const POST_LOGIN_PATH = `${BASE_PATH}/`;
export const COMMON_DATA = "common";
export const SETTINGS_PATH = `${BASE_PATH}/settings`;

export const LOGIN_PATH = `${BASE_PATH}/login`;
export const FORGOT_PATH = `${BASE_PATH}/forgot-password`;

export const CHANGE_FORGOT_PATH = `${BASE_PATH}/reset-password`;
export const UNKNOWN_PATH = `*`;

export const DASH_PATH = `${BASE_PATH}/`;
export const STAFFS_PATH = `${BASE_PATH}/staffs`;

//USERS
export const CUSTOMER_PATH = `${BASE_PATH}/customers`;
export const VIEW_CUSTOMER_PATH = `${BASE_PATH}/view-customer`;
export const VIEW_CUSTOMER_BY_ID_PATH = `${VIEW_CUSTOMER_PATH}/:_id`;

export const SELLERS_APPROVE = `${BASE_PATH}/sellers-approval`;
export const VIEW_APPROVE_SELLER_PATH = `${BASE_PATH}/seller`;
export const VIEW_APPROVE_SELLER_BY_ID_PATH = `${VIEW_APPROVE_SELLER_PATH}/:_id`;

export const DISTRIBUTOR_PATH = `${BASE_PATH}/distributor`;
export const VIEW_DISTRIBUTOR_PATH = `${BASE_PATH}/view-distributor`;
export const VIEW_DISTRIBUTOR_BY_ID_PATH = `${VIEW_DISTRIBUTOR_PATH}/:_id`;

export const RETAILER_PATH = `${BASE_PATH}/retailers`;
export const VIEW_RETAILER_PATH = `${BASE_PATH}/view-retailer`;
export const VIEW_RETAILER_BY_ID_PATH = `${VIEW_RETAILER_PATH}/:_id`;

export const RETAILERS_PATH = `${BASE_PATH}/retailers`;
export const RETAILERS_BY_DISTRIBUTOR_PATH = `${BASE_PATH}/retailer`;
export const RETAILERS_ID_BY_DISTRIBUTOR_PATH = `${RETAILERS_BY_DISTRIBUTOR_PATH}/:_id`;

export const ADD_SELLER_REGISTRATION = `${CUSTOMER_PATH}/:userid/seller-registration`;

export const MECHANIC_PATH = `${BASE_PATH}/mechanics`;
export const VIEW_MECHANIC_PATH = `${BASE_PATH}/view-mechanic`;
export const VIEW_MECHANIC_BY_ID_PATH = `${VIEW_MECHANIC_PATH}/:_id`;

export const PROFILE_PATH = `${BASE_PATH}/profile`;
export const CHANGE_PASSWORD_PATH = `${BASE_PATH}/change-password`;

//Products
export const MANUFACTURER_PATH = `${BASE_PATH}/manufacturer`;
export const ADD_MANUFACTURER_PATH = `${BASE_PATH}/add-manufacturer`;
export const EDIT_MANUFACTURER_PATH = `${BASE_PATH}/edit-manufacturer`;
export const EDIT_MAKER_BY_ID_PATH = `${EDIT_MANUFACTURER_PATH}/:_id`;
export const VIEW_MANUFACTURER_PATH = `${BASE_PATH}/view-manufacturer`;
export const VIEW_MAKER_BY_ID_PATH = `${VIEW_MANUFACTURER_PATH}/:_id`;

export const BRAND_PATH = `${BASE_PATH}/brands`;
export const ADD_BRAND_PATH = `${BASE_PATH}/add-brand`;
export const EDIT_BRAND_PATH = `${BASE_PATH}/edit-brand`;
export const EDIT_BRAND_BY_ID_PATH = `${EDIT_BRAND_PATH}/:_id`;

export const MODEL_PATH = `${BASE_PATH}/models`;
export const ADD_MODEL_PATH = `${MODEL_PATH}/add-model`;
export const EDIT_MODEL_PATH = `${MODEL_PATH}/edit-model`;

export const GENERATION_PATH = `${BASE_PATH}/generation`;
export const ADD_GENERATION_PATH = `${GENERATION_PATH}/add-generation`;
export const EDIT_GENERATION_PATH = `${GENERATION_PATH}/edit-generation`;
export const EDIT_GENERATION_BY_ID_PATH = `${EDIT_GENERATION_PATH}/:_id`;
export const VIEW_GENERATION_PATH = `${BASE_PATH}/view-generation`;
export const VIEW_GENERATION_BY_ID_PATH = `${VIEW_GENERATION_PATH}/:_id`;

export const VARIENT_PATH = `${BASE_PATH}/varients`;
export const ADD_VARIANT_PATH = `${VARIENT_PATH}/add-variant`;
export const EDIT_VARIANT_PATH = `${VARIENT_PATH}/edit-variant`;
export const EDIT_VARIANT_BY_ID_PATH = `${EDIT_VARIANT_PATH}/:_id`;
export const VIEW_VARIANT_PATH = `${BASE_PATH}/view-variant`;
export const VIEW_VARIANT_PATH_BY_ID_PATH = `${VIEW_VARIANT_PATH}/:_id`;

export const COMPONENTS_PATH = `${BASE_PATH}/components`;
export const MAP_COMPONENTS_PATH = `${BASE_PATH}/map-componant-variant`;
export const ADD_MAP_COMPONENTS_PATH = `${BASE_PATH}/add-map-componant-variant`;
export const EDIT_MAP_COMPONENT_PATH = `${MAP_COMPONENTS_PATH}/edit-map-componant-variant`;
export const EDIT_MAP_COMPONENT_BY_ID_PATH = `${EDIT_MAP_COMPONENT_PATH}/:_id`;
export const VIEW_MAP_COMPONANT_PATH = `${MAP_COMPONENTS_PATH}/map-componant-variant`;
export const VIEW_MAP_COMPONANT_PATH_BY_ID_PATH = `${VIEW_MAP_COMPONANT_PATH}/:_id`;

export const ADD_COMPONENT_PATH = `${COMPONENTS_PATH}/add-component`;
export const EDIT_COMPONENT_PATH = `${COMPONENTS_PATH}/edit-component`;
export const EDIT_COMPONENT_BY_ID_PATH = `${EDIT_COMPONENT_PATH}/:_id`;
export const VIEW_COMPONANT_PATH = `${BASE_PATH}/view-component`;
export const VIEW_COMPONANT_PATH_BY_ID_PATH = `${VIEW_COMPONANT_PATH}/:_id`;

export const MAP_SPARE_PARTS_PATH = `${COMPONENTS_PATH}/:component_id/map-spare-parts`;

export const PARTS = `${BASE_PATH}/parts`;
export const ADD_PART_PATH = `${BASE_PATH}/add-part`;
export const EDIT_PART_PATH = `${BASE_PATH}/edit-part`;
export const EDIT_PART_BY_ID_PATH = `${EDIT_PART_PATH}/:_id`;
export const SELLER_PARTS = `${BASE_PATH}/seller-parts`;

export const MAP_SPARE_COMPONENTS_PATH = `${PARTS}/:part_id/map-components`;

//INVENTORY
export const INVENTORY_PATH = `${BASE_PATH}/inventory`;
export const ADD_INVENTORY_PATH = `${INVENTORY_PATH}/add-inventory`;
export const EDIT_INVENTORY_PATH = `${INVENTORY_PATH}/edit-inventory`;
export const EDIT_INVENTORY_BY_ID_PATH = `${EDIT_INVENTORY_PATH}/:_id`;
export const VIEW_INVENTORY_PATH = `${INVENTORY_PATH}/view-inventory`;
export const VIEW_INVENTOR_BY_ID_PATH = `${VIEW_INVENTORY_PATH}/:_id`;

export const PURCHASE_PATH = `${BASE_PATH}/purchase`;
export const ADD_PURCHASE_PATH = `${PURCHASE_PATH}/add-purchase`;
export const EDIT_PURCHASE_PATH = `${PURCHASE_PATH}/edit-purchase`;
export const EDIT_PURCHASE_BY_ID_PATH = `${EDIT_PURCHASE_PATH}/:_id`;

export const PURCHASE_RETURN_PATH = `${BASE_PATH}/purchase-return`;
export const ADD_PURCHASE_RETRUN_PATH = `${PURCHASE_RETURN_PATH}/add-purchase-return`;
export const EDIT_PURCHASE_RETURN_PATH = `${PURCHASE_RETURN_PATH}/edit-purchase-return`;
export const EDIT_PURCHASE_RETURN_BY_ID_PATH = `${EDIT_PURCHASE_RETURN_PATH}/:_id`;

export const SALES_PATH = `${BASE_PATH}/sales`;
export const ADD_SALE_PATH = `${SALES_PATH}/add-sale`;
export const EDIT_SALE_PATH = `${SALES_PATH}/edit-sale`;
export const EDIT_SALE_BY_ID_PATH = `${EDIT_SALE_PATH}/:_id`;

export const DISCOUNT_PATH = `${BASE_PATH}/discount`;

export const CONTACT_US_PATH = `${BASE_PATH}/contact-us`;
export const FAQ_PATH = `${BASE_PATH}/faq`;
export const BLOGS_PATH = `${BASE_PATH}/blogs`;
export const BANNER_PATH = `${BASE_PATH}/banner`;
export const FEEDBACK_PATH = `${BASE_PATH}/feedback`;

export const SALES_RETURN_PATH = `${BASE_PATH}/sales-return`;

export const ORDERS_PATH = `${BASE_PATH}/orders`;
export const ADD_ORDER_PATH = `${ORDERS_PATH}/add-order`;
export const EDIT_ORDER_PATH = `${ORDERS_PATH}/edit-order`;
export const EDIT_ORDER_BY_ID_PATH = `${EDIT_ORDER_PATH}/:_id`;

export const VIEW_RETAILER_ORDERS_PATH = `${ORDERS_PATH}/view-order`;
export const VIEW_RETAILER_ORDER_BY_ID_PATH = `${VIEW_RETAILER_ORDERS_PATH}/:_id/:partId`;

export const VIEW_DISTRIBUTOR_ORDERS_PATH = `${ORDERS_PATH}/view-order`;
export const VIEW_DISTRIBUTOR_ORDER_BY_ID_PATH = `${VIEW_DISTRIBUTOR_ORDERS_PATH}/:_id/:partId`;

export const PAYMNETS_PATH = `${BASE_PATH}/payments`;
export const WITHDRAW_APPROVAL = `${BASE_PATH}/withdraw-approval`;
export const WALLET_STATUS = `${BASE_PATH}/wallet-status`;

export const COUNTRY_PATH = `${BASE_PATH}/country`;
export const ADD_COUNTRY_PATH = `${COUNTRY_PATH}/add-country`;
export const EDIT_COUNTRY_PATH = `${COUNTRY_PATH}/edit-country`;
export const EDIT_COUNTRY_BY_ID_PATH = `${EDIT_COUNTRY_PATH}/:_id`;

//VENDOR && CUSTOMERS
export const VENDORS_PATH = `${BASE_PATH}/vendors`;
export const SELLERS_CUSTOMERS_PATH = `${BASE_PATH}/customers`;

//CATEGORY
export const CATEGORY_PATH = `${BASE_PATH}/categories`;

//ATTRIBUTE
export const ATTRIBUTE_PATH = `${BASE_PATH}/attributes`;

//SEGMENTS
export const SEGMENTS_PATH = `${BASE_PATH}/segments`;

export const test_path = `${BASE_PATH}/images`;

export const SMS_PATH = `${BASE_PATH}/sms`;
export const EMAIL_PATH = `${BASE_PATH}/email`;

//WIZARDS
export const WIZARDS_PATH = `${BASE_PATH}/wizards`;
export const WIZARDS_SEGMENTS_PATH = `${WIZARDS_PATH}/:makerId`;
export const WIZARDS_MODELS_PATH = `${WIZARDS_SEGMENTS_PATH}/:segmentId`;
export const WIZARDS_GENERATION_PATH = `${WIZARDS_MODELS_PATH}/:modelId`;
export const WIZARDS_VARIANT_PATH = `${WIZARDS_GENERATION_PATH}/:generationId`;
export const WIZARDS_COMPONANT_PATH = `${WIZARDS_VARIANT_PATH}/:variantId`;
export const WIZARDS_SUB_COMPONANT_PATH = `${WIZARDS_COMPONANT_PATH}/:componantId`;
export const WIZARDS_END_COMPONANT_PATH = `${WIZARDS_SUB_COMPONANT_PATH}/:subComponantId`;

export const WIZARDS_PARTS_PATH = `${WIZARDS_END_COMPONANT_PATH}/:subComponentId`;
