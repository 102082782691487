export const LOGIN_URL = "auth/login";
export const REFRESH_TOKEN_URL = "auth/exchange-token";
export const FORGOT_PASSWORD = "user/forgotpwd";
export const CHANGE_PASSWORD = "user/changepwd";
export const RESET_PASSWORD = "user/resetpwd";
export const COMMON_DATA = "common";
export const UPLOAD_PHOTOS = "common/images";
export const REMOVE_IMAGE = "remove-image";
export const USERS_FILTERS = "user/filter-users";
export const GET_USER = "user";
export const GET_LOGGED_USER = "user/getLoggedUser";
export const PROFILE_UPDATE = "profile/basic-update";
export const SELLER_APPROVAL = "user/activate-users/";
export const SELLER_REGISTER = "profile";
export const SETTINGS = "setting";
export const ADMIN_DASH_USERS = "dashboard";
export const SELLER_DASH = "seller/dashboard";
export const SELLER_TOP_SELLINGS = "seller/dashboard/top-selling-items";
export const SELLRS_SALES = "seller/dashboard/sales-orders/chart";
export const SALES_ADMIN = "/dashboard/sales/chart";
export const IS_VALID = "wallet/isvalid";
export const IS_EXITS = "auth/isValid";
export const USER_STATUS_CHANGE = "user/change-status/";
export const PART_EXIST = "ispartexists";

//RETAILERS
export const RETAILERS = "wallet/isvalid";
export const DISTRIBUTORS_TO_RETAILERS_ADD = "user/retailer-register";

//WALLET
export const RECHARGE_WALLET = "wallet";
export const WITHDRAW_WALLET = "wallet/withdraw";
export const WITHDRAW_STATUS = "wallet/withdraw/status";
export const WALLET_ACTIVITES = "wallet-activities";

//MAKER
export const MAKER = "maker";
export const MAKER_FILTER = "maker/filter";

//BRAND
export const BRAND = "brand";
export const BRAND_FILTER = "brand/filter";

//COUNTRY
export const COUNTRY_FILTER = "country/filter";
export const COUNTRY = "country";

//COMPONENT
export const COMPONENT = "component";
export const COMPONENT_FILTER = "component/filter";
export const MAP_COMPONENTS_FILTER = "maps/filter";
export const MAP_COMPONENT_CREATE = "maps";

//VARIANT
export const VARIANT_FILTER = "variant/filter";
export const VARIANT = "variant";

//MODEL
export const MODEL_FILTER = "model/filter";
export const MODEL = "model";

//GENERATION
export const GENERATION_FILTER = "generation/filter";
export const GENERATION = "generation";

//INVENTORY
export const INVENTORY_FILTER = "inventory/filter";
export const INVENTORY = "inventory";
export const MAP_INVENTORY_CHECK = "discount/map-inventory-check/";
export const STOCK_ADJUSTMENT = "inventory/stock-adj/";

//PAYMNETS
export const WALLET_PENDING_APPROVEL = "wallet/pending-approval";
export const SEND_AMOUNT = "wallet/withraw/";
export const SEND_PG_RESPONSE = "wallet/pgresponse/";

export const PURCHASE_FILTER = "purchase/filter";
export const PURCHASE = "purchase";

export const PURCHASE_RETURN_FILTER = "purchase-return/filter";
export const PURCHASE_RETURN = "purchase-return";
export const VENDOR_FILTER = "vendor/filter";
export const VENDOR = "vendor";

export const CUSTOMER_FILTER = "customer/filter";
export const CUSTOMER = "customer";

export const SALES_FILTER = "sales/filter";
export const SALES = "sales";

export const SALES_RETURN_FILTER = "sales-return/filter";
export const SALES_RETURN = "sales-return";

export const ORDER = "order";
export const RETAILER_ORDERS = "order/sellers";
export const ORDER_STATUS_CHANGE = "order/status-changes";
export const GET_SELLER_ORDER_DETAILES = "order/sellers/";

//PART
export const PART = "part";
export const PART_FILTER = "part/filter";

//CATEGORY
export const CATEGORY_FILTER = "category/filter";
export const CATEGORY = "category";

//CATEGORY
export const CONTACT_US_FILTER = "contactus/filter";
export const CONTACT_US = "contactus";

//FAQ_
export const FAQ_FILTER = "faq/filter";
export const FAQ_ = "faq";

//BLOGS
export const BLOGS_FILTER = "blog/filter";
export const BLOG = "blog";

//BLOGS
export const FEEDBACK_FILTER = "feedback/filter";
export const FEEDBACK = "feedback";
export const FEEDBACK_APPROVE = "feedback/approve/";

//BANNER
export const BANNER_FILTER = "banner/filter";
export const BANNER = "banner";

//ATTRIBUTE
export const ATTRIBUTE_FILTER = "attribute/filter";
export const ATTRIBUTE = "attribute";

//DISCOUNTS
export const DISCOUNTS_FILTER = "discount/filter";
export const DISCOUNT = "discount";

//MODEL_ADD_FOR_SEGMENT
export const SEGMENTS_BY_BRAND_FILTER = "/brand/segments/";

//SEGMENTS
export const SEGMENTS_FILTER = "segment/filter";
export const SEGMENT = "segment";

//sms
export const SMS_S = "sms";
export const SMS_FILTER = "sms/filter";

//email
export const EMAIL = "email";
export const EMAIL_FILTER = "email/filter";

//STAFFS
export const STAFF_FILTER = "staff/filter";
export const STAFF = "user/staff-register";

export const RETAILER_STAFF = "user/retailer-register";
export const RETAILER_STAFF_FILTER = "staff/filter";

//WIZARDS_FILTER
export const WIZARD_SEGMENT_FILTER = "maker/segments";
export const WIZARD_SEGMENT_MAP = "model/segments/maps";
export const WIZARD_MODEL_FILTER = "model/";
export const WIZARD_GENERATION_FILTER = "generation/";
export const WIZARD_VARIANT_FILTER = "variant/";
export const WIZARD_COMPONANT_FILTER = "component-wizard/";
export const WIZARD_SUB_COMPONANT_FILTER = "subcomponent/";
export const WIZARD_PART_BY_COMPONENT_FILTER = "part/";
export const WIZARD_PART_BY_SUB_COMPONENT_FILTER = "part/";
