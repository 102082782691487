const Footer = () => {
    return (
        <div className="footer">
            <div className="copyright">
                <p>Copyright © Designed &amp; Developed by <a href="#" target="_blank"></a> 2023</p>
            </div>
        </div>
    )
};

export default Footer;