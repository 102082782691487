import axios from "../config/axios";
import { localStorage, utils } from "../helper";
import { CHANGE_PASSWORD, GET_LOGGED_USER, LOGIN_URL, PROFILE_UPDATE } from "./api.url.service";

const Users = {
    login: function ({ email, pwd , loginType}) {
        return axios
            .post(LOGIN_URL, { email, pwd, loginType })
            .then((resp) => {
                const data = utils.handleSuccess(resp);
                localStorage.setAuthToken(data.token);
                return resp.data
            })
            .catch((err) => utils.showErrMsg(utils.handleErr(err)));
    },
    post: function (urlPath, payload) {
        return axios
            .post(urlPath, payload)
            .then((resp) => resp.data)
            .catch((error) => utils.showErrMsg(utils.handleErr(error)));
    },
    changePassword: function (payload) {
        return axios
            .post(CHANGE_PASSWORD, payload)
            .then((resp) => resp.data)
            .catch((error) => utils.showErrMsg(utils.handleErr(error)));
    },

    profileUpdate: function (payload) {
        return axios
            .post(PROFILE_UPDATE, payload)
            .then((resp) => resp.data)
            .catch((error) => utils.showErrMsg(utils.handleErr(error)));
    },

    loggedUser: function (url) {
        return axios
            .get(url)
            .then((resp) => resp.data)
            .catch((error) => utils.showErrMsg(utils.handleErr(error)));
    },
};

export default Users;
