export const setAuthToken = (token) => {
  return localStorage.setItem("token", token);
};

export const getAuthToken = () => {
  const token = localStorage.getItem("token");
  return token ? token : "";
};

export const setAuthUser = (user) => {
  setAuthRefreshToken(user.refreshToken);
  setAuthToken(user.token);
  return localStorage.setItem("user", JSON.stringify(user));
};

export const getAuthUser = () => {
  return JSON.parse(localStorage.getItem("user"));
};

export const removeAuthToken = () => {
  return localStorage.removeItem("token");
};

export const removeAuthUser = () => {
  removeAuthToken();
  removeAuthRefreshToken();
  return localStorage.removeItem("user");
};

export const setLoggedUser = (user) => {
  localStorage.setItem("profile", JSON.stringify(user));
  return true;
};

export const setUserID = (profileID) => {
  return localStorage.setItem("userid", JSON.stringify(profileID));
};

export const getUserID = () => {
  return JSON.parse(localStorage.getItem("userid"));
};

export const removeUserID = () => {
  return localStorage.removeItem("userid");
};

// export const setPurchaseItems = (purchaseItems) => {
//   return localStorage.setItem("purchaseItems", JSON.stringify(purchaseItems));
// };

// export const getPurchaseItems = () => {
//   const purchaseItemsList = JSON.parse(localStorage.getItem("purchaseItems"));
//   console.log("get item::", purchaseItemsList);
//   return purchaseItemsList ? purchaseItemsList : [];
// };

// export const addPurchaseItem = (item) => {
//   let amount;
//   let quanity;
//   console.log("local item ::", item);
//   let itemArr = JSON.parse(localStorage.getItem("purchaseItems")) || [];
//   console.log("itemArr ::", itemArr);
//   itemArr.push(item);
//   let itemsList = getPurchaseItems();
//   let existItem = itemsList.find((ele) => ele.part === item.part);
//   if (existItem) {
//     return setPurchaseItems(itemsList);
//   } else {
//     amount = amount + parseFloat(item.price);
//     quanity = quanity * parseFloat(item.price)
//     return localStorage.setItem(
//       "purchaseItems",
//       JSON.stringify(itemArr)
//     );
//   }
// };


export const getAuthRefreshToken = () => {
  const refreshToken = localStorage.getItem('refreshToken');
  return refreshToken ? refreshToken : ''
};

export const setAuthRefreshToken = (refreshToken) => {
  return localStorage.setItem('refreshToken', refreshToken);
};

export const removeAuthRefreshToken = () => {
  return localStorage.removeItem('refreshToken')
};