import React, { useState, useEffect, useMemo, Fragment } from "react";
import styled from "styled-components";
import DataTable from "react-data-table-component";
import { Button } from "react-bootstrap";

const ClearButton = styled(Button)`
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  height: 34px;
  width: 32px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const TextField = styled.input`
  height: 32px;
  width: 200px;
  border-radius: 3px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border: 1px solid #e5e5e5;
  padding: 0 32px 0 16px;

  &:hover {
    cursor: pointer;
  }
`;

const FilterComponent = ({ filterText, onFilter, onClear }) => (
  <div className="input-group mb-3">
    <TextField
      type="text"
      placeholder="Filter By Name"
      aria-label="Search Input"
      value={filterText}
      onChange={onFilter}
    />
    <div className="input-group-append">
      <ClearButton type="button" className="p-0" onClick={onClear}>
        X
      </ClearButton>
    </div>
  </div>
);

const customStyles = {
  rows: {
    style: {
      minHeight: "72px", // override the row height
    },
  },
  headCells: {
    style: {
      paddingLeft: "8px", // override the cell padding for head cells
      paddingRight: "8px",
    },
  },
  cells: {
    style: {
      paddingLeft: "8px", // override the cell padding for data cells
      paddingRight: "8px",
    },
  },
};

function PartsDataTable(props) {
  const {
    title,
    // filterKey,
    // filterKeyArr,
    addLink,
    noHeader = true,
    subHeader = true,
    data,
    progressPending,
    columns,
    // exportLink,
    // exportFunc,
    downloadFuntion,
    totalRows,
    handlePerRowsChange,
    handlePageChange,
    // coOrdinate
  } = props;

  const actionsMemo = useMemo(() => {
    return (
      <Fragment>
        {addLink && (
          <button className="btn btn-rounded btn-success" onClick={addLink}>
            + Add
          </button>
        )}
      </Fragment>
    );
  }, [data]);

  const [filterText, setFilterText] = React.useState("");
  const [resetPaginationToggle, setResetPaginationToggle] =
    React.useState(false);

  const subHeaderComponentMemo = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <FilterComponent
        onFilter={(e) => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle]);

  return (
    <div>
      <DataTable
        className={`table table-striped table-bordered align-middle mb-0`}
        title={title}
        noHeader={noHeader}
        responsive
        pagination
        paginationRowsPerPageOptions={[5]}
        paginationResetDefaultPage={resetPaginationToggle}
        // highlightOnHover={coOrdinate}
        pointerOnHover
        progressPending={progressPending}
        addLink={actionsMemo}
        dense
        data={data}
        columns={columns}
        customStyles={customStyles}
        paginationServer
        paginationTotalRows={totalRows}
        onChangeRowsPerPage={handlePerRowsChange}
        onChangePage={handlePageChange}
        subHeader={subHeader}
        subHeaderComponent={subHeaderComponentMemo}
      />
    </div>
  );
}

export default PartsDataTable;
