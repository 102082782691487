import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import 'react-toastify/dist/ReactToastify.css';
import "../node_modules/metismenujs/dist/metismenujs.css";
import "../node_modules/react-datepicker/dist/react-datepicker.css";
// import "./pages/assets/css/pagination.scss";
import "./pages/assets/icons/flaticon/flaticon.css";
import './pages/assets/css/style.css';
import "./pages/assets/css/custom.css"
import { Provider } from "react-redux";
import { BrowserRouter } from 'react-router-dom';
import Container from './pages/container';
import store from "./core/store";
import "./i18n"

function App() {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <Container />
      </BrowserRouter>
    </Provider>
  )
}

export default App;
