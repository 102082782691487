import React from "react";
import { Dropdown } from "react-bootstrap";
import { Link } from "react-router-dom";

export default function Actions(props) {
  const {
    editOnClick,
    userActivate,
    userDeActivate,
    userDelete,
    viewOnClick,
    deleteOnClick,
    rowId,
    isParentId,
    viewUrl,
    editUrl,
    mapSparePartsUrl,
    mapComponentsUrl,
    convertSellerUrl,
    userStatus,
    status,
    _id,
    stockAdjustment,
    isApprove,
    isFeedbackApprove,
    makeItApprove,
  } = props;

  const customAction = React.forwardRef(({ children, onClick }, ref) => (
    <span style={{ cursor: "pointer" }} ref={ref} onClick={onClick}>
      {children}
      {/* <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <rect x="0" y="0" width="24" height="24"></rect>
          <circle fill="#000000" cx="5" cy="12" r="2"></circle>
          <circle fill="#000000" cx="12" cy="12" r="2"></circle>
          <circle fill="#000000" cx="19" cy="12" r="2"></circle>
        </g>
      </svg> */}
      <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1">
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <rect x="0" y="0" width="24" height="24"></rect>
          <circle fill="#000000" cx="5" cy="12" r="2"></circle>
          <circle fill="#000000" cx="12" cy="12" r="2"></circle>
          <circle fill="#000000" cx="19" cy="12" r="2"></circle>
        </g>
      </svg>
    </span>
  ));

  return (
    <Dropdown>
      <Dropdown.Toggle as={customAction}></Dropdown.Toggle>
      <Dropdown.Menu
        style={{
          position: "inherit",
          inset: "auto",
          top: "0px",
          bottom: "0px",
        }}
        className="shadow"
      >
        {editUrl && (
          <Link className="dropdown-item" to={editUrl}>
            Edit
          </Link>
        )}
        {editOnClick && (
          <Dropdown.Item onClick={() => editOnClick(rowId)}>
            <Link>Edit</Link>
          </Dropdown.Item>
        )}
        {viewUrl && (
          <Link className="dropdown-item" to={viewUrl}>
            View
          </Link>
        )}
        {viewOnClick && (
          <Dropdown.Item onClick={() => viewOnClick(rowId, isParentId)}>
            <Link>View</Link>
          </Dropdown.Item>
        )}
        {deleteOnClick && (
          <Dropdown.Item onClick={() => deleteOnClick(rowId, _id)}>
            Delete
          </Dropdown.Item>
        )}
        {status === 10 ? (
          <Dropdown.Item onClick={() => userDeActivate(rowId, userStatus)}>
            Make it de-active
          </Dropdown.Item>
        ) : (
          userActivate && (
            <Dropdown.Item onClick={() => userActivate(rowId, userStatus)}>
              Make it active
            </Dropdown.Item>
          )
        )}
        {userDelete && (
          <Dropdown.Item onClick={() => userDelete(rowId)}>
            Make it delete
          </Dropdown.Item>
        )}
        {mapSparePartsUrl && (
          <Link className="dropdown-item" to={mapSparePartsUrl}>
            Map Spare Parts
          </Link>
        )}
        {mapComponentsUrl && (
          <Link className="dropdown-item" to={mapComponentsUrl}>
            Map Components
          </Link>
        )}
        {convertSellerUrl && (
          <Link className="dropdown-item" to={convertSellerUrl}>
            Convert to seller
          </Link>
        )}
        {stockAdjustment && (
          <Dropdown.Item onClick={() => stockAdjustment(rowId)}>
            Stock Adjustment
          </Dropdown.Item>
        )}
        {!isApprove && isFeedbackApprove && (
          <Dropdown.Item onClick={() => makeItApprove(rowId)}>
            Make it approve
          </Dropdown.Item>
        )}
      </Dropdown.Menu>
    </Dropdown>
  );
}

// <div>
//     {
//         editOnClick && (
//             <OverlayTrigger overlay={<Tooltip>Edit</Tooltip>}>
//                 <button className="btn btn-inverse-info btn-rounded btn-icon" onClick={() => editOnClick(rowId)}>
//                     <i className="mdi mdi-pencil"></i>
//                 </button>
//             </OverlayTrigger>
//         )
//     }
//     {
//         editUrl && (
//             <OverlayTrigger overlay={<Tooltip>Edit</Tooltip>}>
//                 <Link className="btn btn-inverse-info btn-rounded btn-icon" to={editUrl}>
//                     <i style={{lineHeight:'2.2'}} className="mdi mdi-pencil"></i>
//                 </Link>
//             </OverlayTrigger>
//         )
//     }
//     {
//         viewOnClick && (
//             <OverlayTrigger overlay={<Tooltip>View</Tooltip>}>
//                 <button className="btn btn-inverse-primary btn-rounded btn-icon" onClick={() => viewOnClick(rowId)}>
//                     <i className="mdi mdi-eye"></i>
//                 </button>
//             </OverlayTrigger>
//         )
//     }
//     {
//         deleteOnClick && (
//             <OverlayTrigger overlay={<Tooltip>Delete</Tooltip>}>
//                 <button className="btn btn-inverse-danger btn-rounded btn-icon" onClick={() => deleteOnClick(rowId)}>
//                     <i className="mdi mdi-delete"></i>
//                 </button>
//             </OverlayTrigger>
//         )
//     }
//     {
//         viewUrl && (
//             <OverlayTrigger overlay={<Tooltip>View</Tooltip>}>
//                 <Link className="btn btn-inverse-primary btn-rounded btn-icon" to={viewUrl}>
//                     <i style={{lineHeight:'2.2'}} className="mdi mdi-eye"></i>
//                 </Link>
//             </OverlayTrigger>
//         )
//     }
// </div>
