import Metismenu from "@metismenu/react";
import { Fragment, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { CONST } from "core/helper";

const Sidebar = () => {
  const router = useLocation();

  const user = useSelector((state) => state.account?.authUser);
  const [headerSticky, setHeaderSticky] = useState(false);
  const handleScroll = () => {
    if (window.pageYOffset > 0) {
      setHeaderSticky(true);
    } else {
      setHeaderSticky(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.addEventListener("scroll", handleScroll);
  }, []);
  // ${
  //   headerSticky ? "ps--active-y" : ""
  // }
  return (
    <div className="deznav">
      <div className={`deznav-scroll mm-active`}>
        <Metismenu className="metismenu mm-show" id="menu">
          <li className={router.pathname === "/" ? "mm-active" : ""}>
            <Link to={"/"}>
              <i className="flaticon-381-networking"></i>
              <span className="nav-text">Dashboard</span>
            </Link>
          </li>
          {(user?.role === CONST.ROLE.ADMIN ||
            user?.role === CONST.ROLE.STAFF_ROLE) && (
            <Fragment>
              <li
                className={router.pathname === "/customers" ? "mm-active" : ""}
              >
                <a className="has-arrow ai-icon" href="#" aria-expanded="false">
                  <i className="flaticon-381-user"></i>
                  <span className="nav-text">Users</span>
                </a>
                <ul aria-expanded="false">
                  <li>
                    <Link to={"/customers"}>B2C Customers</Link>
                  </li>
                  <li>
                    <Link to={"/mechanics"}>B2C Mechanics</Link>
                  </li>
                  <li>
                    <Link to={"/retailers"}>B2B Retailer</Link>
                  </li>
                  <li>
                    <Link to={"/distributor"}>B2B Distributor</Link>
                  </li>
                  <li>
                    <Link to={"/sellers-approval"}>Sellers Approval</Link>
                  </li>
                  <li>
                    <Link to={"/staffs"}>Staffs</Link>
                  </li>
                </ul>
              </li>
              {/* {(user?.role === CONST.ROLE.ADMIN ||
                user?.role === CONST.ROLE.DISTRIBUTER ||
                user?.role === CONST.ROLE.STAFF_ROLE) && (
                <li>
                  <Link to="/staffs">
                    <i className="flaticon-381-heart"></i>
                    <span className="nav-text">Staffs</span>
                  </Link>
                </li>
              )} */}
              <li>
                <Link to="/wizards">
                  <i className="flaticon-381-internet"></i>
                  <span className="nav-text">Wizards</span>
                </Link>
              </li>
              <li>
                <a className="has-arrow ai-icon" href="#" aria-expanded="false">
                  <i className="flaticon-381-television"></i>
                  <span className="nav-text">Products</span>
                </a>
                <ul aria-expanded="false">
                  <li>
                    <Link to={"/manufacturer"}>Manufacturer</Link>
                  </li>
                  <li>
                    <Link to={"/segments"}>Segments</Link>
                  </li>
                  <li>
                    <Link to={"/brands"}>Brands</Link>
                  </li>
                  <li>
                    <Link to={"/models"}>Models</Link>
                  </li>
                  <li>
                    <Link to={"/generation"}>Generation</Link>
                  </li>
                  <li>
                    <Link to={"/varients"}>Variants</Link>
                  </li>
                  <li>
                    <Link to={"/map-componant-variant"}>
                      Map Component Variant
                    </Link>
                  </li>
                  <li>
                    <Link to={"/parts"}>Parts</Link>
                  </li>
                  <li>
                    <Link to={"/seller-parts"}>Un Apporval Parts</Link>
                  </li>
                </ul>
              </li>
              <li>
                <a className="has-arrow ai-icon" href="#" aria-expanded="false">
                  <i className="flaticon-381-network"></i>
                  <span className="nav-text">Parts</span>
                </a>
                <ul aria-expanded="false">
                  <li>
                    <Link to={"/categories"}>Categories</Link>
                  </li>
                  <li>
                    <Link to={"/attributes"}>Attributes</Link>
                  </li>
                  <li>
                    <Link to={"/components"}>Product Grouping</Link>
                  </li>
                </ul>
              </li>
              <li>
                <Link to={"/orders"}>
                  <i className="flaticon-381-layer-1"></i>
                  <span className="nav-text">Orders</span>
                </Link>
              </li>
              <li>
                <a className="has-arrow ai-icon" href="#" aria-expanded="false">
                  <i className="flaticon-381-diamond"></i>
                  <span className="nav-text">Payments</span>
                </a>
                <ul aria-expanded="false">
                  <li>
                    <Link to={"/payments"}>Payments</Link>
                  </li>
                  <li>
                    <Link to={"/withdraw-approval"}>withdraw-approval</Link>
                  </li>
                </ul>
              </li>
              <li>
                <a className="has-arrow ai-icon" href="#" aria-expanded="false">
                  <i className="flaticon-381-internet"></i>
                  <span className="nav-text">Common</span>
                </a>
                <ul aria-expanded="false">
                  <li>
                    <Link to="/sms">SMS Template</Link>
                  </li>
                  <li>
                    <Link to="/email">Email Template</Link>
                  </li>
                  <li>
                    <Link to="/faq">FAQ</Link>
                  </li>
                  <li>
                    <Link to="/blogs">Blogs</Link>
                  </li>
                  <li>
                    <Link to="/feedback">Feedback</Link>
                  </li>
                  <li>
                    <Link to="/banner">Banner</Link>
                  </li>
                  <li>
                    <Link to="/contact-us">Contact-us</Link>
                  </li>
                </ul>
              </li>
            </Fragment>
          )}
          {user?.role === CONST.ROLE.DISTRIBUTER && (
            <Fragment>
              <li>
                <a className="has-arrow ai-icon" href="#" aria-expanded="false">
                  <i className="flaticon-381-user"></i>
                  <span className="nav-text">Users</span>
                </a>
                <ul aria-expanded="false">
                  <li>
                    <Link to={"/customers"}>Customers</Link>
                  </li>
                  <li>
                    <Link to={"/retailers"}>Retailer</Link>
                  </li>
                  <li>
                    <Link to={"/vendors"}>Vendors</Link>
                  </li>
                  <li>
                    <Link to={"/staffs"}>Staffs</Link>
                  </li>
                </ul>
              </li>
              <li>
                <Link to={"/inventory"}>
                  <i className="flaticon-381-network"></i>
                  <span className="nav-text">Inventories</span>
                </Link>
              </li>
              <li>
                <Link to={"/orders"}>
                  <i className="flaticon-381-layer-1"></i>
                  <span className="nav-text">Orders</span>
                </Link>
              </li>
              <li>
                <Link to={"/purchase"}>
                  <i className="flaticon-381-heart"></i>
                  <span className="nav-text">Purchase</span>
                </Link>
              </li>
              <li>
                <a
                  href="/widget-basic/"
                  className="has-arrow  ai-icon"
                  aria-expanded="false"
                >
                  <i className="flaticon-381-settings-2"></i>
                  <span className="nav-text">Sales</span>
                </a>
                <ul aria-expanded="false">
                  <li>
                    <Link to="/sales">Sales</Link>
                  </li>
                  <li>
                    <Link to="/sales-return">Sales Return</Link>
                  </li>
                </ul>
              </li>
              <li>
                <Link to="/discount">
                  <i className="fa fa-tag fa-lg"></i>
                  <span className="nav-text">Discount</span>
                </Link>
              </li>
            </Fragment>
          )}
          {user?.role === CONST.ROLE.RETAILER && (
            <Fragment>
              <li>
                <a className="has-arrow ai-icon" href="#" aria-expanded="false">
                  <i className="flaticon-381-user"></i>
                  <span className="nav-text">Users</span>
                </a>
                <ul aria-expanded="false">
                  <li>
                    <Link to={"/customers"}>Customers</Link>
                  </li>
                  <li>
                    <Link to={"/vendors"}>Vendors</Link>
                  </li>
                </ul>
              </li>
              <li>
                <Link to={"/inventory"}>
                  <i className="flaticon-381-network"></i>
                  <span className="nav-text">Inventories</span>
                </Link>
              </li>
              <li>
                <Link to={"/purchase"}>
                  <i className="flaticon-381-heart"></i>
                  <span className="nav-text">Purchase</span>
                </Link>
              </li>
              <li>
                <Link to={"/orders"}>
                  <i className="flaticon-381-layer-1"></i>
                  <span className="nav-text">Orders</span>
                </Link>
              </li>
              <li>
                <a
                  href="/widget-basic/"
                  className="has-arrow  ai-icon"
                  aria-expanded="false"
                >
                  <i className="flaticon-381-settings-2"></i>
                  <span className="nav-text">Sales</span>
                </a>
                <ul aria-expanded="false">
                  <li>
                    <Link to="/sales">Sales</Link>
                  </li>
                  <li>
                    <Link to="/sales-return">Sales Return</Link>
                  </li>
                </ul>
              </li>
              <li>
                <Link to="/discount">
                  <i className="fa fa-tag fa-lg"></i>
                  <span className="nav-text">Discount</span>
                </Link>
              </li>
            </Fragment>
          )}
        </Metismenu>
      </div>
    </div>
  );
};

export default Sidebar;
