import { CHAT_TOGGLE_ACTION, PROFILE_ACTION, SET_COMMON_DATA, SIDEBAR_TOGGLE_ACTION } from "../constant";

export const chatToggleAction = () => dispatch => {
    dispatch({
        type: CHAT_TOGGLE_ACTION
    })
};

export const sidebarToggleAction = () => dispatch => {
    dispatch({
        type: SIDEBAR_TOGGLE_ACTION
    })
};

export const setCommonDataAction = (payload) => dispatch => {
    dispatch({
        type: SET_COMMON_DATA,
        payload
    })
};

export const reloadProfileAction = (payload) => dispatch => {
    dispatch({
        type: PROFILE_ACTION,
        payload
    })
};