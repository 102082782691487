import { CHAT_TOGGLE_ACTION, PROFILE_ACTION, SET_COMMON_DATA, SIDEBAR_TOGGLE_ACTION } from "../constant";

const initialState = {
    sidebarToggle: false,
    chatboxTogle: false,
    commonData: {},
    reloadProfile: false
};

const common = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case SIDEBAR_TOGGLE_ACTION:
            return {
                ...state,
                sidebarToggle: !state.sidebarToggle
            }
        case CHAT_TOGGLE_ACTION:
            return {
                ...state,
                chatboxTogle: !state.chatboxTogle
            }
        case SET_COMMON_DATA:
            return {
                ...state,
                commonData: payload
            }
        case PROFILE_ACTION:
            return {
                ...state,
                reloadProfile: !state.reloadProfile
            }
        default:
            return {
                ...state
            }
    }
};

export default common;