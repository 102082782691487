import { SET_LOGGED_IN_USER, REMOVE_LOGGED_IN_USER, SET_LOGGED_USER_DATA } from '../constant';

export const loginAction = (user) => dispatch => {
    dispatch({
        type: SET_LOGGED_IN_USER,
        payload: user,
    })
};

export const logoutAction = () => dispatch => {
    dispatch({
        type: REMOVE_LOGGED_IN_USER,
        payload: {
            isLoggedIn: false,
            token: '',
            authUser: '',
        }
    })
};

export const loggedProfileDataAction = (payload) => dispatch => {
    dispatch({
        type: SET_LOGGED_USER_DATA,
        payload,
    })
};