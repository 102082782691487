import ReactPaginate from "react-paginate";

const Pagination = (props) => {
  const { pageCount, onPageChange, initialPage } = props;

  return (
    <ReactPaginate
      initialPage={initialPage}
      pageCount={pageCount}
      onPageChange={onPageChange}
      className="pagination pangination-circle"
      containerClassName="page-item"
      disabledClassName={"navigationDisabled"}
      activeClassName="active"
      previousClassName="page-indicator page-item"
      nextClassName="page-indicator page-item"
      previousLinkClassName={"page-link"}
      nextLinkClassName={"page-link"}
      pageClassName="page-item"
      previousLabel={<i className="la la-angle-left"></i>}
      nextLabel={<i className="la la-angle-right"></i>}
      pageLinkClassName="page-link"
      //   breakClassName="page-link"
    />
  );
};

export default Pagination;
