import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

const Breadcrumb = (props) => {
  const {
    pageFor,
    addPath,
    handleShowFilter,
    add,
    filterClear,
    isFilter,
    resetFilter,
    isDirty,
  } = props;
  return (
    <Row className="page-titles">
      <ol className="breadcrumb d-flex justify-content-between">
        <Col md={5} className="d-flex align-items-center">
          <li className="breadcrumb-item active">
            <span className="fs-3">{pageFor}</span>
          </li>
        </Col>
        <Col md={7}>
          <li className="ms-auto d-flex justify-content-end align-items-center">
            {addPath && (
              <Link to={addPath}>
                <button className="btn btn-rounded btn-primary add_btn">
                  Add
                </button>
              </Link>
            )}
            {add && (
              <button
                onClick={add}
                className="btn btn-rounded btn-primary btn-sm add_btn"
              >
                Add
              </button>
            )}
            {!isFilter && (
              <button
                type="button"
                onClick={handleShowFilter}
                className="btn btn-rounded filter_btn bg-dark"
              >
                Filter
              </button>
            )}
            {isDirty && (
              <button
                type="button"
                onClick={resetFilter}
                className="btn btn-rounded btn-danger clear_filter_btn"
              >
                Clear
              </button>
            )}
          </li>
        </Col>
      </ol>
    </Row>
  );
};

export default Breadcrumb;
