import { Offcanvas } from "react-bootstrap";

const CommonFilter = (props) => {
  const { children, show, onHide } = props;
  return (
    <Offcanvas
      placement="end"
      scroll={true}
      backdrop={false}
      show={show}
      onHide={onHide}
    >
      <Offcanvas.Header className="filter_header" closeButton>
        <Offcanvas.Title className="filter_title">Filter</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body className="p-0">{children}</Offcanvas.Body>
      {/* <Offcanvas.Header className="filter_header">
        <button type="submit" className="btn btn-primary">
          Apply Filter
        </button>
      </Offcanvas.Header> */}
    </Offcanvas>
  );
};

export default CommonFilter;
