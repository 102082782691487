import axios from "../config/axios";
import { utils } from "../helper";
import { IS_EXITS, IS_VALID, PART_EXIST } from "./api.url.service";

const Products = {
  get: function (urlPath) {
    return axios
      .get(urlPath)
      .then((resp) => resp.data)
      .catch((error) => utils.showErrMsg(utils.handleErr(error)));
  },

  getAll: function (urlPath) {
    return axios
      .get(urlPath)
      .then((resp) => resp.data)
      .catch((error) => utils.showErrMsg(utils.handleErr(error)));
  },

  getAllPost: function (urlPath, filter) {
    return axios
      .post(urlPath, filter)
      .then((resp) => resp.data)
      .catch((error) => utils.showErrMsg(utils.handleErr(error)));
  },

  getAllPostUsers: function (urlPath, filter) {
    return axios
      .get(urlPath, filter)
      .then((resp) => resp.data)
      .catch((error) => utils.showErrMsg(utils.handleErr(error)));
  },

  create: function (urlPath, payload) {
    return axios
      .post(urlPath, payload)
      .then((resp) => resp.data)
      .catch((error) => utils.showErrMsg(utils.handleErr(error)));
  },

  update: function (urlPath, payload) {
    return axios
      .put(urlPath, payload)
      .then((resp) => resp.data)
      .catch((error) => utils.showErrMsg(utils.handleErr(error)));
  },

  getById: function (urlPath) {
    return axios
      .get(urlPath)
      .then((resp) => resp.data)
      .catch((error) => utils.showErrMsg(utils.handleErr(error)));
  },

  getByIdFilter: function (urlPath, filter) {
    return axios
      .get(urlPath, filter)
      .then((resp) => resp.data)
      .catch((error) => utils.showErrMsg(utils.handleErr(error)));
  },

  delete: function (urlPath) {
    return axios
      .delete(urlPath)
      .then((resp) => resp.data)
      .catch((error) => utils.showErrMsg(utils.handleErr(error)));
  },

  patch: function (urlPath, payload) {
    return axios
      .patch(urlPath, payload)
      .then((resp) => resp.data)
      .catch((error) => utils.showErrMsg(utils.handleErr(error)));
  },

  getAllFilter: function (urlPath, filter) {
    Object.keys(filter).forEach((f) => filter[f] === "" && delete filter[f]);
    const filterString = new URLSearchParams(filter).toString();
    return axios
      .get(urlPath + "?" + filterString)
      .then((resp) => resp.data)
      .catch((error) => utils.showErrMsg(utils.handleErr(error)));
  },

  sellerFilter: function (urlPath, code) {
    return axios
      .get(urlPath + "?type=" + code)
      .then((resp) => resp.data)
      .catch((error) => utils.showErrMsg(utils.handleErr(error)));
  },

  isValid: function (payload) {
    return axios
      .post(IS_VALID, payload)
      .then((resp) => resp.data)
      .catch((error) => utils.showErrMsg(utils.handleErr(error)));
  },

  isExists: function (payload) {
    return axios
      .post(IS_EXITS, payload)
      .then((resp) => resp.data)
      .catch((error) => utils.showErrMsg(utils.handleErr(error)));
  },

  isPartExists: function (payload) {
    return axios
      .post(PART_EXIST, payload)
      .then((resp) => resp.data)
      .catch((error) => utils.showErrMsg(utils.handleErr(error)));
  },
};

export default Products;
