// Reference: https://medium.com/how-to-react/setup-multilingual-in-react-js-using-i18n-module-33b1bfbb57cd
import i18n from "i18next";
// import HttpBackend from "i18next-http-backend";
// import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

// Importing translation files
import translationEN from "./locale/en/common.json";
import translationHE from "./locale/hn/common.json";

// const fallbackLng = ["en"];
// const availableLanguages = ["en", "hi"];

//Creating object with the variables of imported translation files
const resources = {
  en: {
    translation: translationEN,
  },
  hn: {
    translation: translationHE,
  },
};

i18n
  //   .use(HttpBackend)
  //   .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    lng: "en", //default language
    keySeparator: false,
    interpolation: {
      escapeValue: false, // no need for react. it escapes by default
    },
    // fallbackLng,
    // detection: {
    //   checkWhitelist: true,
    // },
    // debug: false,
    // whitelist: availableLanguages,
  });

export default i18n;
